import React, { useEffect } from 'react'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import Title from '../../components/Title'
import TodoCard from '../../components/TodoCard'

// import eget hook - som laver request til API
import { useGetData } from '../../hooks/useGetData'


const Todos = () => {

    // request-hook - 
    const { error, loading, data, getData } = useGetData()

    useEffect( () => {

        getData( "https://api.airtable.com/v0/appbPSktuwOdhtpi7/Todotable",
            { "Authorization": "Bearer " + process.env.REACT_APP_AIRTABLEKEY }
            ,
            [ { field: "Todos", direction: "desc" } ]
        )

    }, [] )




    return (
        <div className="Todos container">

            <Title headline="Mine TODOS!" />

            {/* Error */ }
            { error && <Error /> }

            {/* Loading */ }
            { loading && <Loader /> }



            <div className="row row-cols-1 row-cols-md-4 g-2">

                {/* Data - species */ }

                { data && data.records.map( ( t ) =>

                    // <div className={ i === data.results.length - 1 ? "col" : "col2" } key={ "starship" + i }>
                    <div className="col" key={ t.id }>
                        <TodoCard t={t} />
                    </div>

                ) }
                
            </div>
        </div>
    )
}


export default Todos