import React, { useEffect } from 'react'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import Title from '../../components/Title'
import { Link } from 'react-router-dom'

// icons
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';

// import eget hook - som laver request til API
import { useGetData } from '../../hooks/useGetData'
// import eget hook - giver adgang til delete
import { useDeleteData } from '../../hooks/useDeleteData'

const TodosAdmin = () => {

    // request-hook
    const { error, loading, data, getData } = useGetData()
    const { error: errordelete, loading: loadingdelete, data: datadelete, deleteData } = useDeleteData()

    useEffect( () => {

        getData( "https://api.airtable.com/v0/appbPSktuwOdhtpi7/Todotable",
            { "Authorization": "Bearer " + process.env.REACT_APP_AIRTABLEKEY }
            ,
            [ { field: "Todos", direction: "desc" } ]
        )

    }, [ datadelete ] ) // lytter på ændringer i datadelete-state (fra delete-hook) og henter (nye) data ved ændringer


    // Kald hook og api og slet
    const handleDelete = ( id ) => {


        if ( window.confirm( "Er du sikker på at du vil slette?" ) ) {

            // console.log("Slet todo nu - med id: ", id )
            deleteData( "https://api.airtable.com/v0/appbPSktuwOdhtpi7/Todotable/" + id,
                { "Authorization": "Bearer " + process.env.REACT_APP_AIRTABLEKEY }
            )
        }
    }


    return (
        <div className="Todosadmin container">

            <Title headline="ADMIN TODOS!" />

            {/* Error */ }
            { ( error || errordelete ) && <Error /> }

            {/* Loading */ }
            { ( loading || loadingdelete ) && <Loader /> }



            <div className="row row-cols-1 row-cols-md-4 g-2">

                {/* Data - species */ }

                { data && data.records.map( ( t ) =>

                    // <div className={ i === data.results.length - 1 ? "col" : "col2" } key={ "starship" + i }>
                    <div className="col" key={ t.id }>
                        <div className="card h-100  bg-primary">
                            <div className="card-body">
                                <h4>{ t.fields.Todos }</h4>
                                <p>{t.fields.Categoryname}</p>
                                <p>
                                    { new Date( t.createdTime ).toLocaleDateString( "da-dk", { year: "numeric", month: "short", day: "numeric" } ) }
                                    &nbsp;kl.&nbsp;
                                    { new Date( t.createdTime ).toLocaleTimeString( "da-dk", { hour: "2-digit", minute: "2-digit" } ) }
                                </p>
                            </div>
                            <div className="card-footer">
                                {/* <button className="btn btn-warning me-2">RET</button> */ }
                                {/* <button onClick={ () => handleDelete( t.id ) } className="btn btn-danger">SLET</button> */ }
                                <Link to={ "/rettodo/" + t.id }><AiOutlineEdit size="2em" /></Link>
                                <AiOutlineDelete onClick={ () => handleDelete( t.id ) } style={ { cursor: "pointer" } } size="2em" />
                            </div>
                        </div>
                    </div>

                ) }
            </div>
        </div>
    )
}


export default TodosAdmin