import React, { useEffect, useState } from 'react'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import Title from '../../components/Title'

// import eget hook - som laver request til API
import { useGetData } from '../../hooks/useGetData'


const Starships = () => {

    // request-hook - 
    const { error, loading, data, getData } = useGetData()

    // state til håndtering af den side jeg vil have vist
    const [ pageNumber, setPageNumber ] = useState( 1 )


    useEffect( () => {

        getData( "https://swapi.dev/api/starships/?page=" + pageNumber )

    }, [ pageNumber ] )




    return (
        <div className="Starships container">

            <Title headline="Starships" />

            {/* Error */ }
            { error && <Error /> }

            {/* Loading */ }
            { loading && <Loader /> }

            {
                data &&

                <>

                    <div className="my-5">

                        <button disabled={ data.previous ? false : true } onClick={ () => { setPageNumber( pageNumber - 1 ) } } className="btn btn-success mx-2">&lt;&lt; Prev </button>
                        <button disabled={ data.next ? false : true } onClick={ () => { setPageNumber( pageNumber + 1 ) } } className="btn btn-success mx-2">Next &gt;&gt;</button>
                    </div>


                    <div className="row row-cols-1 row-cols-md-4 g-2">

                        {/* Data - species */ }

                        { data.results.map( ( d, i ) =>

                            // <div className={ i === data.results.length - 1 ? "col" : "col2" } key={ "starship" + i }>
                            <div className="col" key={ "starship" + i }>
                                <div className="card h-100  bg-primary">
                                    <div className="card-body">
                                        <h4>{ d.name }</h4>
                                    </div>
                                    <div className="card-text">
                                        <ul>
                                            <li>Classification: { data.classification }</li>
                                            <li>Designation: { data.designation }</li>
                                            <li>Language: { data.language }</li>
                                            <li>Average height: { data.average_height }</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        ) }

                    </div>


                </>
            }
        </div>
    )
}

export default Starships