import React from 'react'
import Header from '../layout/Header'
// https://mdbcdn.b-cdn.net/img/Photos/Others/images/76.webp
const Home = () => {
    return (
        <section className="Home bg-image" style={ { backgroundSize: "cover", backgroundImage: "url('/assets/img/igor-sporynin-dzSX6c2wiyk-unsplash.jpg')", height: "100vh" } }>

            <div className="container">
                <Header />
            </div>


            {/* Lav overskrifter til childcomp ... øv det med genbrug!!! */ }
            <h1 className="display-2 my-4 text-center">Home</h1>


        </section>
    )
}

export default Home