import { useState } from 'react'
import axios from 'axios';


export const useDeleteData = () => {

    // States til håndtering af data, loading, error
    const [ data, setData ] = useState()
    const [ error, setError ] = useState( false )
    const [ loading, setLoading ] = useState( false )

    // headers og params af hensyn til fx RapidAPI
    const deleteData = ( url, headers = null, params = null ) => {

        setLoading( true )              // api'et "ringes op om lidt" så sæt loading til true
        //setData()                     // her kan du tømme state med data, hvis de bør nulstilles/fjernes inden hentning af nye data

        axios.delete( url, { headers: headers, params: params } )
            .then( res => {
                console.log( res.data )
                setData( res.data )     // success - der er data - put dem i state
                setError( false )       // ... så ingen fejl
            } )
            .catch( err => {
                setError( true )        // ups fejl
                setData()               // ... så tøm data der KAN være fejlagtige
            } )
            .finally( () => {
                setLoading( false )     // uanset om der er data eller fejl så - finally - stop loading
            } )
    }

    // det der "udbydes" fra hooket her
    return { deleteData, error, loading, data }
}

