import { useState } from 'react'
import axios from 'axios';


export const usePostData = () => {

    const [ data, setData ] = useState()
    const [ error, setError ] = useState( false )
    const [ loading, setLoading ] = useState( false )

    // payload er de data der skal postes/oprettes
    const postData = ( url, payload = null, headers = null, params = null ) => {

        setLoading( true )              // api'et "ringes op om lidt" så sæt loading til true

        axios.post( url, payload, { headers: headers, params: params } )
            .then( res => {
                console.log( res.data )
                setData( res.data )     // success - der er data - put dem i state
                setError( false )       // ... så ingen fejl
            } )
            .catch( err => {
                setError( true )        // ups fejl
                setData()               // ... så tøm data der KAN være fejlagtige
            } )
            .finally( () => {
                setLoading( false )     // uanset om der er data eller fejl så - finally - stop loading
            } )
    }

    // det der "udbydes" fra hooket her
    return { postData, error, loading, data }
}

