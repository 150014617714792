import React from 'react'

const Title = ( { headline, children } ) => {

    return (
        <>
            <h1 className="my-5 text-center">{ headline }</h1>
            { children }
        </>
    )
}

export default Title