import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import Home from './pages/Home';
import News from './pages/news/News';
import Users from './pages/jsonplaceholder/Users';
import Species from './pages/swapi/Species';
import Starships from './pages/swapi/Starships';
import NoMatch from './pages/NoMatch';
import Hobbies from './pages/rapidapi/Hobbies';
import Facts from './pages/rapidapi/Facts';
import LoveCalc from './pages/rapidapi/LoveCalc';
import Weather1 from './pages/openweather/Weather1';
import Weather2 from './pages/openweather/Weather2';
import Weather2b from './pages/openweather/Weather2b';
import MinTest from './components/MinTest';
import Weather3 from './pages/openweather/Weather3';
import Todos from './pages/airtable/Todos';
import TodoCreate from './pages/airtable/TodoCreate';
import TodosAdmin from './pages/airtable/TodosAdmin';
import TodoEdit from './pages/airtable/TodoEdit';

function App () {
  return (
    <div className="App">

      <BrowserRouter>

        <Navbar />
        {/* <Header /> */ }

        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/user" element={ <Users /> } />
          <Route path="/species" element={ <Species /> } />
          <Route path="/starships" element={ <Starships /> } />
          <Route path="/news" element={ <News /> } />
          <Route path="/hobbies" element={ <Hobbies /> } />
          <Route path="/facts" element={ <Facts /> } />
          <Route path="/lovecalc" element={ <LoveCalc /> } />
          <Route path="/weather1" element={ <Weather1 /> } />
          <Route path="/weather2" element={ <Weather2 /> } />
          <Route path="/weather3" element={ <Weather3 /> } />
          <Route path="/weather2b" element={ <Weather2b /> } />
          <Route path="/todos" element={ <Todos /> } />
          <Route path="/oprettodo" element={ <TodoCreate /> } />
          <Route path="/rettodo/:id" element={ <TodoEdit /> } />
          <Route path="/admintodo" element={ <TodosAdmin /> } />



          <Route path="/mintest" element={ <MinTest /> } />

          <Route path="*" element={ <NoMatch /> } />

        </Routes>

        <Footer />

      </BrowserRouter>


    </div>
  );
}

export default App;
