import React, { useEffect, useState } from 'react'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import Title from '../../components/Title'

// import map 
import { initMap, changeMapView, removeMap } from '../../helpers/leaflet'


// import eget hook - som laver request til API
import { useGetData } from '../../hooks/useGetData'

// Med opslag på adresser/postnumre hos DAWA
const Weather3 = () => {

    // request-hook - openweather
    const { error, loading, data, getData } = useGetData()
    // request-hook - DAWA
    const { error: errorDAWA, loading: loadingDAWA, data: dataDAWA, getData: getDataDAWA } = useGetData()

    // state til species ID
    const [ zip, setZip ] = useState( "8000" ) // load data for 8000 eller noget andet


    useEffect( () => {

        // overvej regex - regular expression
        if ( zip.length === 4 && !isNaN( zip ) ) {
            getData( "https://api.openweathermap.org/data/2.5/weather?zip=" + zip + ",dk&units=metric&lang=da&appid=" + process.env.REACT_APP_OPENWEATHERAPIKEY )
        }
        // else {
        // søg i dawa og send brugerens input med (state )
        getDataDAWA( "https://api.dataforsyningen.dk/postnumre/autocomplete?q=" + zip )
        // }

    }, [ zip ] )


    useEffect( () => {

        // hvis der er data (og dermed koordinater til et valgt postnummer) flyt kort-view til postnr-koordinater

        //  if ( data ) changeMapView( [ data.coord.lat, data.coord.lon ], data.weather[ 0 ].description )

        if ( data && dataDAWA ) changeMapView( [ dataDAWA[ 0 ].postnummer.visueltcenter_y, dataDAWA[ 0 ].postnummer.visueltcenter_x ], data.weather[ 0 ].description )

    }, [ data ] ) // lytter efter data fra openweather (med koordinater!)




    // init map
    useEffect( () => {

        initMap( [ 56, 10 ] )

        return () => {
            removeMap() // fjern kortet når component unmountes
        }
    }, [] )



    return (
        <div className="Weather2 container">

            <Title headline="Vejret - med adresseopslag hos DAWA" />

            {/* Error */ }
            { error && <Error /> }

            {/* Loading */ }
            { loading && <Loader /> }

            <div className="row">
                <div className="col-12 mb-5 text-center">

                    {/* INPUT - til postnummer/by */ }
                    <input list="adresseforslag"
                        type="text"
                        defaultValue={ zip }
                        onInput={ ( e ) => setZip( e.target.value.substring( 0, 4 ) ) }
                        autoComplete="off"
                        placeholder="Indtast et postnummer"
                    />

                    {/* DATALIST - med forslag til input */ }
                    <datalist id="adresseforslag">
                        {
                            dataDAWA && dataDAWA.map( a => <option value={ a.tekst } key={ a.postnummer.nr } /> )
                        }
                    </datalist>
                </div>


                {/* https://getbootstrap.com/docs/5.0/components/card/#horizontal */ }
                <div className="col-12">
                    <div class="card mb-3" >
                        <div class="row g-0">


                            <div class="col-md-5">
                                <div class="card-body  p-5">
                                    {
                                        data && <>
                                            <h5 class="card-title">Vejret i { data.name }</h5>

                                            <p className="card-text cap-first display-4">{ data.weather[ 0 ].description }  </p>
                                            <p className="card-text">Temperatur: { Math.round( data.main.temp ) }&#8451; </p>
                                            <p className="card-text">Luftfugtighed: { data.main.humidity }%</p>
                                            <img src={ "http://openweathermap.org/img/wn/" + data.weather[ 0 ].icon + "@2x.png" } />
                                        </>
                                    }
                                </div>
                            </div>

                            {/* KORT */ }
                            <div id="mapcontainer" class="col-md-7 text-center" style={ { height: "350px" } }>
                                Kortet er på vej ....
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Weather3