import React from 'react'
import './loader.scss'

const Loader = () => {
    return (

        // styling mv. findes her: https://www.w3schools.com/howto/howto_css_loader.asp
        <div className="position-absolute top-50 start-50 translate-middle" style={ { zIndex: 600 } }>
            <div className="loader"></div>
        </div>
    )
}

export default Loader