import React from 'react'
import { NavLink, Link } from 'react-router-dom'

import cat from '../assets/undraw_cat_re_gkh9.svg'

const Navbar = () => {


    return (

        <nav className="navbar navbar-expand-lg bg-light">
            <div className="container">

                <Link className="navbar-brand" href="#">
                    <img src={ cat } alt="logo" width="80" />
                </Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">

                        <li className="nav-item">
                            <NavLink end className="nav-link" aria-current="page" to="/">Home</NavLink>
                        </li>

                        {/* ----- JSONPlaceholder ----- */ }
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" data-bs-toggle="dropdown">JSONPlaceholder</span>
                            <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/user">Find en user</NavLink></li>
                            </ul>
                        </li>

                        {/* ----- SWAPI ----- */ }
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" data-bs-toggle="dropdown">SWAPI</span>
                            <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/species">Species</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/starships">Starships</NavLink></li>
                            </ul>
                        </li>

                        {/* ----- NewsAPI ----- */ }
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" data-bs-toggle="dropdown">NewsAPI</span>
                            <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/news">Søg nyheder</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/news">Andre nyheder</NavLink></li>
                            </ul>
                        </li>

                        {/* ----- RapidAPI ----- */ }
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" data-bs-toggle="dropdown">RapidAPI</span>
                            <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/hobbies">Få en hobby</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/facts">Facts</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/lovecalc">Love Calculator</NavLink></li>
                            </ul>
                        </li>

                        {/* ----- OpenWeather ----- */ }
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" data-bs-toggle="dropdown">OpenWeather</span>
                            <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/weather1">Vis vejret (postnummer)</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/weather2">Vis vejret m/DAWA</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/weather3">Vis vejret m/DAWA og map/kort</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/weather2b">TEST</NavLink></li>
                            </ul>
                        </li>

                        {/* ----- AIRTABLE ----- */ }
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Airtable</span>
                            <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/todos">Mine todos - todo!</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/admintodo">ADMIN todo</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/oprettodo">Opret todo</NavLink></li>
                            </ul>
                        </li>

                        {/* <li className="nav-item">
                            <NavLink className="nav-link" aria-current="page" to="/noget">Noget</NavLink>
                        </li> */}


                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar