import React from 'react'
import './pagination.scss'

const Pagination = ( { currentPage, setCurrentPage, itemsPerPage, itemsTotal } ) => {

    // Beregn hvor mange "sider" der skal være - beregn ud fra antal-items-ialt divideret med items-per-side og "rund op" (ceil)
    let numberOfPages = Math.ceil( itemsTotal / itemsPerPage )


    return (
        <div className="Pagination">

            {/* PREVIOUS */ }
            <button
                disabled={ currentPage <= 0 }
                onClick={ () => setCurrentPage( currentPage - 1 ) }
                className="btn btn-success">
                &lt;&lt; Prev
            </button>

            {/* SWIPE-BUTTONS Pagination side-buttons - 1 button pr. side */ }
            {
                [ ...Array( numberOfPages ) ].map( ( x, i ) =>

                    <button onClick={ () => setCurrentPage( i ) } className={ i === currentPage ? " btn btn-success" : "btn btn-primary" } key={ "page" + i }>
                        { i + 1 }
                    </button>

                )
            }

            {/* NEXT */ }
            <button
                disabled={ currentPage >= numberOfPages - 1 }
                onClick={ () => setCurrentPage( currentPage + 1 ) }
                className="btn btn-success">
                &gt;&gt; Next
            </button>

        </div>
    )
}

export default Pagination