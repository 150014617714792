import React, { useEffect, useState, useRef } from 'react'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import Title from '../../components/Title'
//CSS
import './weather.scss'

// import eget hook - som laver request til API
import { useGetData } from '../../hooks/useGetData'

// Med opslag på adresser/postnumre hos DAWA

const Weather2b = () => {

    // request-hook - openweather
    const { error, loading, data, getData } = useGetData()
    // request-hook - DAWA
    const { error: errorDAWA, loading: loadingDAWA, data: dataDAWA, getData: getDataDAWA } = useGetData()

    // state til species ID
    const [ zip, setZip ] = useState( "8000" )


    // hold øje med om der skrives i input
    const inputFocus = useRef( false )


    useEffect( () => {

        // overvej regex - regular expression
        if ( zip.length === 4 && !isNaN( zip ) ) {

            inputFocus.current = false;

            getData( "https://api.openweathermap.org/data/2.5/weather?zip=" + zip + ",dk&units=metric&lang=da&appid=" + process.env.REACT_APP_OPENWEATHERAPIKEY )
        } else {
            inputFocus.current = true;

            // søg i dawa og send brugerens input med (state )
            getDataDAWA( "https://api.dataforsyningen.dk/postnumre/autocomplete?q=" + zip )
        }

    }, [ zip ] )


    return (
        <div className="Weather2 container">

            <Title headline="Vejret - adresseopslag hos DAWA" />

            {/* Error */ }
            { error && <Error /> }

            {/* Loading */ }
            { loading && <Loader /> }




            <div className="row">

                <div className="col-12 mb-5 text-center">

                    {/* Input postnummer/by */ }
                    <input list="adresseforslag"
                        type="text"
                        onInput={ ( e ) => setZip( e.target.value ) }
                        // defaultValue={ zip }
                        value={ zip }
                        autoComplete="off"
                        placeholder="Indtast et postnummer"
                    />

                    <div className={ inputFocus.current ? "show" : "hide" }>
                        <ul>
                            {
                                dataDAWA && dataDAWA.map( a => <li onClick={ ( e ) => setZip( a.postnummer.nr ) } key={ a.postnummer.nr }>{ a.tekst } </li> )
                            }
                        </ul>
                    </div>
                </div>






                <div className="col-12 col-md-6 offset-md-3">

                    {
                        data && <article className="card ">
                            <div className="card-body">
                                <div className="card-title">
                                    <h2>Vejret i { data.name }</h2>
                                </div></div>
                            <div className="card-body">
                                <p className="cap-first display-4">{ data.weather[ 0 ].description }  </p>
                                <p>Temperatur: { Math.round( data.main.temp ) }&#8451; </p>
                                <p>Luftfugtighed: { data.main.humidity }%</p>
                                <img src={ "http://openweathermap.org/img/wn/" + data.weather[ 0 ].icon + "@2x.png" } />
                            </div>
                        </article>
                    }


                </div>
            </div>
        </div>
    )
}

export default Weather2b