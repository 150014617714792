import React from 'react'

const Footer = () => {
    return (
        <footer className="text-muted py-5 mt-5 bg-dark text-light border-top border-primary">
            <div className="container">
                <p className="float-end mb-1">
                    <span href="/">Back to top</span>
                </p>
                <p className="mb-1">Hello, is it me you're looking for? I can see it in your eyes ...</p>
                <p className="mb-0">Tell me how to win your heart, for I haven't got a clue.</p>
                <p>&copy; Idiotic WebDev Inc 2022 - since 1970</p>
            </div>
        </footer>
    )
}

export default Footer