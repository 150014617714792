import React, { useEffect, useState } from 'react'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import Title from '../../components/Title'

// import eget hook - som laver request til API
import { useGetData } from '../../hooks/useGetData'


const Species = () => {

    // request-hook - 
    const { error, loading, data, getData } = useGetData()

    // state til species ID
    const [ speciesID, setSpeciesID ] = useState( 1 )

    useEffect( () => {

        // Undgå kald til api hvis der ikke er en ID i state
        if ( speciesID !== "" ) {

            getData( "https://swapi.dev/api/species/" + speciesID )
        }


    }, [ speciesID ] )




    return (
        <div className="Species container">

            <Title headline="Species" />

            {/* Error */ }
            { error && <Error /> }

            {/* Loading */ }
            { loading && <Loader /> }


            <div className="row">
                <div className="col-12 col-md-6">

                    <form className="my-3">
                        <label htmlFor="inpSpecID">Tast en species ID</label>
                        <input type="number" defaultValue={ speciesID } onInput={ e => setSpeciesID( e.target.value ) } min="1" className="form-control" placeholder="Species ID" id="inpSpecID" />
                    </form>
                </div>

                <div className="col-12 col-md-6">

                    {/* Data - species */ }
                    {
                        data &&
                        <div className="card-body">
                            <div className="card-title">
                                <h2>{ data.name }</h2>
                            </div>
                            <div className="card-text">
                                <ul>
                                    <li>Classification: { data.classification }</li>
                                    <li>Designation: { data.designation }</li>
                                    <li>Language: { data.language }</li>
                                    <li>Average height: { data.average_height }</li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>



            </div>
        </div>
    )
}

export default Species