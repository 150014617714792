import React from 'react'


// data fns - til at formatere datoer til "nu"
import { formatDistanceToNow } from 'date-fns'
import { da } from 'date-fns/locale'

const NewsCard = ( { newsEvent } ) => {

    return (

        <div className="col">

            <div className="card h-100">

                {/* Håndter at urlToImage kan være null */ }

                {
                    //newsEvent.urlToImage && <img src={ newsEvent.urlToImage } alt={ newsEvent.title } className="card-img-top" />
                }

                {
                    //newsEvent.urlToImage ? <img src={ newsEvent.urlToImage } alt={ newsEvent.title } className="card-img-top" /> : null
                    newsEvent.urlToImage
                        ? <img src={ newsEvent.urlToImage } alt={ newsEvent.title } className="card-img-top" />
                        : <svg class="bd-placeholder-img card-img-top" width="100%" height="200" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c" /><text x="50%" y="50%" fill="#eceeef" dy=".3em"  text-anchor="middle">Photo missing</text></svg>
                }

                {/* <img src={ newsEvent.urlToImage ? newsEvent.urlToImage : "https://via.placeholder.com/100" } alt={ newsEvent.title } className="card-img-top" /> */ }


                <div className="card-body">

                    <div className="title">
                        <h4>{ newsEvent.title }</h4>

                        <p><small className="text-muted">{ formatDistanceToNow( new Date( newsEvent.publishedAt ), { locale: da, addSuffix: true, includeSeconds: true } ) }</small></p>

                        <p><small className="text-muted">{ new Date( newsEvent.publishedAt ).toLocaleString( "da-dk", { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" } ) }</small></p>

                    </div>

                    <div className="card-text">
                        <p>{ newsEvent.description }</p>
                        <p><a href={ newsEvent.url } target="_blank">Læs mere</a></p>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default NewsCard