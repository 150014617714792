import React, { useEffect, useState } from 'react'
import Title from '../../components/Title'
import { useParams, useNavigate } from 'react-router-dom'
import { useGetData } from '../../hooks/useGetData'
import { usePatchData } from '../../hooks/usePatchData'
import Loader from '../../components/Loader'
import Error from '../../components/Error'

// HVILKEN ID??????? skal rettes = indlæses i formu

const TodoEdit = () => {

    const { id } = useParams() // snup id fra url (tjek i App.jsx - hvor "id" er navngivet/sat)
    const navigate = useNavigate() // så brugeren kan redirectes retur til adminsiden efter rettelse

    const { error, loading, data, getData } = useGetData() // hent todo der skal rettes
    const { error: errorCategories, loading: loadingCategories, data: dataCategories, getData: getDataCategories } = useGetData() // hente kategorier

    const { error: errorPatch, loading: loadingPatch, data: dataPatch, patchData } = usePatchData()

    // state til at rumme den rettede todo - todo og kategori
    const [ updatedTodo, setUpdatedTodo ] = useState()
    const [ updatedCategory, setupdatedCategory ] = useState()



    // 1) Hent den todo der skal rettes (når component loader)
    useEffect( () => {

        // Kategorier så man evt. kan vælge en anden kategori
        getDataCategories( "https://api.airtable.com/v0/appbPSktuwOdhtpi7/Category",
            { "Authorization": "Bearer " + process.env.REACT_APP_AIRTABLEKEY }
        )


        // Todo der skal rettes
        getData( "https://api.airtable.com/v0/appbPSktuwOdhtpi7/Todotable/" + id,
            {
                "Authorization": "Bearer " + process.env.REACT_APP_AIRTABLEKEY,
                "Content-Type": "application/json"
            } )
    }, [] )


    // 3) Lyt efter rettelser - og redirect til adminsiden når...
    useEffect( () => {

        // hvis der er data fra patch-requestet = færdig med at rette
        if ( dataPatch ) {
            navigate( '/admintodo' )
        }

    }, [ dataPatch ] )



    // 2) Send data til api
    const handleSubmit = ( e ) => {
        e.preventDefault() // VIGTIG ved submit af form

        // Hvis der er rettet så tag det rettede fra state - hvis der IKKE er rettet så hent det fra de data vi modtog med GET-metoden
        let todo = updatedTodo ? updatedTodo : data.fields.Todos
        let cat = updatedCategory ? updatedCategory : data.fields.Category[ 0 ]

        let t = {
            "fields": {
                "Todos": todo,
                "Category": [
                    cat
                ]
            }
        }

        // send til hook som sender til API
        patchData( "https://api.airtable.com/v0/appbPSktuwOdhtpi7/Todotable/" + id, t,
            {
                "Authorization": "Bearer " + process.env.REACT_APP_AIRTABLEKEY,
                "Content-Type": "application/json"
            }
        )
    }




    return (
        <div className="container">

            <Title headline="Ret udvalgt todo" />

            { ( loading || loadingPatch || loadingCategories ) && <Loader /> }
            { ( error || errorPatch || errorCategories ) && <Error /> }

            <div className="row">
                <div className="col">

                    { data &&

                        <form onSubmit={ handleSubmit }  >

                            {/* Todo tekst */ }
                            <div className="mb-3 mt-3">
                                <label className="form-label me-3">Ret todo:
                                    {/*  */ }
                                    <input type="text" defaultValue={ data.fields.Todos } onInput={ e => setUpdatedTodo( e.target.value ) } className="form-control" />
                                </label>
                            </div>


                            {/* Kategorierne i dropdown */ }
                            <div className="mb-3 mt-3">

                                <label className="form-label me-3">Vælg en kategori

                                    <select defaultValue={ data.fields.Category[ 0 ] } onChange={ e => setupdatedCategory( e.target.value ) } className="form-select">
                                        {
                                            dataCategories && dataCategories.records.map( c =>
                                                <option value={ c.id } key={ c.id }>
                                                    { c.fields.Name }
                                                </option>
                                            )
                                        }
                                    </select>
                                </label>

                            </div>




                            <button type="submit" className="btn btn-primary"> Ret todo</button>
                        </form>
                    }
                </div>
            </div>
        </div >
    )
}

export default TodoEdit