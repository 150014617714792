import { useState } from 'react'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import Title from '../../components/Title'

// import eget hook - som laver request til API
import { useGetData } from '../../hooks/useGetData'


// https://rapidapi.com/ajith/api/love-calculator/

const LoveCalc = () => {

    const { error, loading, data, getData } = useGetData()

    // State til 2 navne
    const [ fname, setFname ] = useState()
    const [ sname, setSname ] = useState()


    // ved klik på fx knap
    const handleSubmit = ( e ) => {
        e.preventDefault()

        getData( "https://love-calculator.p.rapidapi.com/getPercentage",
            {
                'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPIKEY,
                'X-RapidAPI-Host': 'love-calculator.p.rapidapi.com'
            },
            { fname: fname, sname: sname }
        )
    }

    return (

        <div className="Lovecalc container">

            <Title headline="Test succes-raten inden du dater! ❤" />
            <p className="text-center">... eller skift navn på dig selv eller din udkårne!</p>

            { loading && <Loader /> }
            { error && <Error /> }


            <div className="row mt-5">
                <div className="col mb-3 mt-3">

                    {/* FORM */ }
                    <form onSubmit={ handleSubmit } className="col-6 mb-3 mt-3">

                        <div className="mb-3 mt-3">
                            <input type="text" onInput={ e => setFname( e.target.value ) } className="form-control" placeholder="Det ene navn" />
                        </div>

                        <div className="mb-3 mt-3">
                            <input type="text" onInput={ e => setSname( e.target.value ) } className="form-control" placeholder="Det andet navn" />
                        </div>
                        <div className=" mb-3 mt-3">
                            <button className="btn btn-success mt-5">Vis min succes-procent!</button>
                        </div>
                    </form>


                    { data && <article className="card">
                        <div className="card-body">
                            <div className="card-title text-center">

                                <h2 className="display-2">{ data.percentage } %</h2>
                                <h4> { data.result }</h4>
                            </div>

                        </div>
                    </article>
                    }
                </div>


            </div>

        </div >
    )
}

export default LoveCalc