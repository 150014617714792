import React, { useEffect, useState } from 'react'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import Title from '../../components/Title'

// import eget hook - som laver request til API
import { useGetData } from '../../hooks/useGetData'


const Users = () => {

    // request-hook - 
    const { error, loading, data, getData } = useGetData()

    // state til users ID
    const [ userID, setUserID ] = useState( 5 )

    useEffect( () => {

        // Undgå kald til api hvis der ikke er en ID i state
        if ( userID !== "" ) {

            getData( "https://jsonplaceholder.typicode.com/users/" + userID )
        }


    }, [ userID ] )




    return (
        <div className="Users container">
            <div className="row">

                {/* <h1>Users</h1> */ }
                <Title headline="Users" />


                {/* Error */ }
                { error && <div className="col-12"><Error errormessage="Måske er der ikke en bruger som matcher den indtastede ID?" /></div> }

                {/* Loading */ }
                { loading && <div className="col-12"><Loader /></div> }


                <div className="col-12 col-md-6">
                    <form className="my-3">
                        <label htmlFor="inpUserID">Tast en brugers ID</label>
                        <input type="number" onInput={ e => setUserID( e.target.value ) } min="1" className="form-control" placeholder="Brugers ID" id="inpUserID" />
                    </form>
                </div>

                <div className="col-12 col-md-6">

                    {/* Data - users */ }
                    {
                        data && data.address &&
                        <div className="card">
                            <div className="card-body">
                                <h2>{ data.name }</h2>

                                <p>Mail: <a href={ "mailto:" + data.email }>{ data.email }</a></p>
                                <p>By: { data.address.city }</p>

                                {
                                    Object.entries( data.address ).slice( 0, 4 ).map( ( [ k, v ] ) =>
                                        <p key={ v }>{ k }: { v }</p>
                                    )
                                }

                            </div>
                        </div>
                    }
                </div>





            </div>
        </div>
    )
}

export default Users