import React, { useEffect, useState } from 'react'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import Pagination from '../../components/pagination/Pagination'
import Title from '../../components/Title'

// import eget hook - som laver request til API
import { useGetData } from '../../hooks/useGetData'



const Facts = () => {

    // API KALD
    const { error, loading, data, getData } = useGetData()

    // pagination
    const [ currentPage, setCurrentPage ] = useState( 0 )   // hvilken side skal vises nu - side 1 = 0
    const [ itemsPerPage, setItemsPerPage ] = useState( 6 ) // hvor mange/antal et-eller-andet pr. side


    useEffect( () => {

        getData( "https://facts-by-api-ninjas.p.rapidapi.com/v1/facts",
            {
                'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPIKEY,
                'X-RapidAPI-Host': 'facts-by-api-ninjas.p.rapidapi.com'
            },
            { limit: "30" }
        )

    }, [] )



    return (
        <div className="Facts container">
            {/* https://rapidapi.com/apininjas/api/facts-by-api-ninjas */ }

            <Title headline="Facts!" />

            { loading && <Loader /> }

            { error && <Error errormessage="Ingen data - øv" /> }


            {/* ---- PAGINATION ---- */ }
            {
                data &&
                <Pagination currentPage={ currentPage } setCurrentPage={ setCurrentPage } itemsPerPage={ itemsPerPage } itemsTotal={ data.length } />
            }



            { data && data.slice( currentPage * itemsPerPage, ( currentPage * itemsPerPage ) + itemsPerPage ).map( ( f, i ) =>
                <div className="card" key={ "fact" + i }>
                    <div className="card-body">
                        <small>{ data.indexOf( f ) }</small>
                        <h4>{ f.fact }</h4>
                    </div>
                </div>
            ) }








        </div>
    )
}

export default Facts